import illustration from "../../images/illustration.png";
import clsx from "clsx";

const partners = [
    {
        name: "Creators Area",
        icon: "/assets/media/partners/gca.png",
        href: "https://creatorsarea.fr/"
    },
    {
        name: "SFAIT",
        icon: "/assets/media/partners/sfait.webp",
        href: "https://sfait.fr/"
    },
    {
        name: "ChatGPT Community",
        icon: "/assets/media/partners/chatgpt.webp",
        href: "https://discord.gg/chatgpt-community-1057784677093605466"
    }
]

export function HeroSection() {
    return (
        <div className="bg-theme2-500">
            <div className="relative isolate overflow-hidden bg-gradient-to-b from-theme-700 py-14">
                <div
                    className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right from-20% bg-gradient-to-br from-theme2-600 to-theme-950 skew-x-[-30deg] shadow-xl shadow-theme-600/10 ring-1 ring-theme-50 sm:-mr-80 lg:-mr-96"
                    aria-hidden="true"
                />
                <div className="mx-auto max-w-7xl px-6 py-32 sm:py-40 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
                        <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-white sm:text-6xl lg:col-span-2 xl:col-auto">
                            The future of Artificial Intelligence on Discord
                        </h1>
                        <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
                            <p className="text-lg leading-8 text-gray-400">
                                Manage multiple threaded discussions, each with its own context for consistent discussion with ChatGPT, chat by voice with the bot and generate images !
                                Configure the bot to your liking and enable/disabled what you want.
                                All this to give back activity to your server, to allow your members to have clear and precise answers and to have fun !
                            </p>
                            <div className="mt-10 flex items-center gap-x-6">
                                <a
                                    href="/invite" target="_blank" rel="noreferrer"
                                    className="rounded-lg bg-theme-500 px-5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-theme-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-600"
                                >
                                    Add the bot
                                </a>
                                <a href="/support" target="_blank" rel="noreferrer" className="text-sm font-semibold leading-6 text-white">
                                    Support server
                                </a>
                            </div>
                        </div>
                        <img
                            src={illustration}
                            alt=""
                            className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
                        />
                    </div>
                </div>
                <div className="text-center px-6 lg:px-8 py-12">
                    <h2 className="text-center text-lg font-semibold leading-8 text-white">
                        Trusted by and in partnership with
                    </h2>
                    <div className="mt-10 flex justify-center flex-wrap gap-x-8 gap-y-10 sm:grid-cols-6 sm:gap-x-10">
                        {partners.map((partner, i) =>
                            <a key={i} href={partner.href} rel="noreferrer" target="_blank" className="flex gap-4 items-center group">
                                <img className={clsx("rounded-2xl grayscale-[90%] brightness-120 h-12 transition-all group-hover:brightness-100 group-hover:rounded-md group-hover:grayscale-0", partner.iconStyle)} src={partner.icon} alt={partner.name} />
                                <p className="text-white text-lg font-semibold">{partner.name}</p>
                            </a>
                        )}
                    </div>
                </div>
                <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-theme2-500 sm:h-32" />
            </div>
        </div>
    );
}