import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements, redirect } from 'react-router-dom';
import { AlertContainer } from './components/Misc/Alerts';
import Home from './components/Home';
import { fetchData } from './lib/service';
import { canRefresh, isLogged, refreshToken } from './lib/service/authentification';
import { fetchUser } from './lib/service/user';
import "./styles/main.css";
import "./styles/tailwind.css";
import Error404 from './components/404';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

function App() {
    const [user, setUser] = useState(null);
    const [data, setData] = useState({});
    const [alerts, setAlerts] = useState([]);

    function addAlert(alert) {
        setAlerts(a => [...a, { id: Date.now() + Math.round(Math.random() * 1000), ...alert }]);
    }

    useEffect(() => {
        (async () => {
            const suser = JSON.parse(sessionStorage.getItem('user'));
            const lastupdate = sessionStorage.getItem('lastupdate') || 0;
            if (isLogged()) {
                if (!user) {
                    if (suser && Date.now() - lastupdate < 1000 * 20) {
                        setUser(suser);
                        return;
                    }
                    else {
                        const tuser = await fetchData(addAlert, setUser, fetchUser);
                        if (tuser) sessionStorage.setItem("lastupdate", Date.now());
                        return;
                    }
                }

                sessionStorage.setItem('user', JSON.stringify(user));
            } else {
                try {
                    if (!canRefresh()) throw new Error();
                    const tuser = await refreshToken();
                    if (!tuser) throw new Error();
                    setUser(tuser);
                    sessionStorage.setItem("lastupdate", Date.now());
                    return;
                } catch (error) {
                    if (user) setUser(null);
                    if (suser) sessionStorage.removeItem('user');
                }
            }
        })();
    }, [user]);

    const props = { user, setUser, data, setData, addAlert };

    const router = createBrowserRouter(
        createRoutesFromElements(<>
            <Route exact path="/" ErrorBoundary={Error404} element={<Home {...props} />} />

            <Route exact path="/shop" element={null} loader={() => redirect("https://smartcord.mysellix.io/", { status: 301 })} />
            <Route exact path="/support" element={null} loader={() => redirect("https://discord.gg/u8ehamrEea", { status: 301 })} />
            <Route exact path="/vote" element={null} loader={() => redirect("https://top.gg/bot/1058008641959112796/vote", { status: 301 })} />
            <Route exact path="/invite" element={null} loader={() => redirect("https://discord.com/api/oauth2/authorize?client_id=1058008641959112796&permissions=395140525072&scope=bot%20applications.commands", { status: 301 })} />
            <Route exact path="/topgg" element={null} loader={() => redirect("https://top.gg/bot/1058008641959112796", { status: 301 })} />
        </>
        )
    );

    return (
        <>
            <AlertContainer alerts={alerts} setAlerts={setAlerts} />
            {
                (isLogged() ? user : true) &&
                <RouterProvider router={router} />
            }
        </>
    );
}